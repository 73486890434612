@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #16a34a; /* bg-green-600 */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.toggle__line {
  transition: background 0.3s ease-in-out;
}

.toggle__dot {
  top: -0.25rem;
  left: -0.25rem;
  transition: all 0.3s ease-in-out;
}

@keyframes rainbow {
  0% {
    background: red;
  }

  14% {
    background: orange;
  }

  28% {
    background: yellow;
  }

  42% {
    background: lime;
  }

  57% {
    background: blue;
  }

  71% {
    background: indigo;
  }

  85% {
    background: violet;
  }

  100% {
    background: red;
  }
}

input:checked~.toggle__line {
  animation: rainbow 2s linear infinite;
}

input:checked~.toggle__dot {
  transform: translateX(100%);
  animation: rainbow 2s linear infinite;
}
